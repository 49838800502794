<template>
<div class="content">
  <div class="bg-gradient-to-t from-gray-200">
    <spotlight class="pb-12"/>
  </div>

  <div class="bg-gradient-to-b from-gray-200 to-green-200">
    <preview class="pb-12"/>
  </div>
</div>  
</template>

<script>
import spotlight from '../components/landing/spotlight.vue'
import preview from '../components/landing/preview.vue'

export default {
  name: 'Home',
  components: {
    spotlight,
    preview
  },

  head() {
    return {
      title: 'Curated',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'A place to preceive art directly through the eyes and minds of others.'
        }
      ]
    };
  }
}

</script>

<style scoped>
.content {
  max-width: 100vw;
}
</style>
