<template>
  <footer aria-labelledby="footerHeading">
    <div class="relative bg-black">
        <div class="max-w-4xl mx-auto px-4">
            <div class="relative pt-12 pb-12 sm:pt-24 sm:pb-12 xl:col-start-1 xl:pb-12">
                <h2 class="text-sm font-semibold tracking-wide uppercase">
                    <router-link to="/">
                        <span class="text-white p-8 sm:p-4 hover:text-gray-500">Home</span>
                    </router-link>
                    <router-link to="/about">
                        <span class="text-white p-8 sm:p-4 hover:text-gray-500">About</span>
                    </router-link>
                    <router-link to="/contact">
                        <span class="contact text-white p-8 sm:p-4 hover:text-gray-500">Contact</span>
                    </router-link>
                   <router-link to="/gallery">
                        <span class="gallery text-white p-8 sm:p-4 hover:text-gray-500">Gallery</span>
                    </router-link>
                </h2>
                <div class="mt-12 border-t border-gray-400 pt-4">
                    <p class="text-base text-gray-200 xl:text-center">
                        &copy; 2021 Curated, All images and videos used on the website belong to their respective owners and Curated does not claim any right over them.
                    </p>
                </div>
            </div>
        </div>
    </div>    
  </footer>
</template>

<script>

export default {
    name: 'Footer'
}
</script>

<style>
@media only screen and (max-width: 450px) {
  .contact {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .gallery {
    display: none;
  }
}
</style>