<template>
  <Popover open="true" class="relative bg-white">
    <div class="flex justify-start items-start px-4 py-6 justify-start space-x-10">
      <div class="flex justify-start w-0 flex-1">
        <router-link to="/">
          <img class="h-8 w-auto h-8" src="../assets/svg/Curated.svg" alt="" />
        </router-link>
      </div>
      <PopoverGroup as="nav" class="flex space-x-10">
        <Popover class="relative" v-slot="{ open }">
          <PopoverButton :class="[open ? 'text-black' : 'text-black', 'group bg-white rounded-md inline-flex items-center text-base text-lg font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black']">
            <span>Discover</span>
            <ChevronDownIcon :class="[open ? 'text-black' : 'text-black', 'ml-2 h-5 w-5 group-hover:text-gray-500']" />
          </PopoverButton>

          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs px-0">
              <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div class="relative grid gap-6 bg-white px-5 py-6 gap-8 p-8">
                  <a v-for="item in discover" :key="item.name" :href="item.href" class="-m-3 p-3 block rounded-md hover:bg-gray-50">
                    <p class="text-base font-medium text-gray-900">
                      {{ item.name }}
                    </p>
                    <p class="mt-1 text-sm text-gray-500">
                      {{ item.description }}
                    </p>
                  </a>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <router-link to="/gallery">
          <a href="gallery" class="text-base text-lg font-medium mr-4 text-black hover:text-gray-500">
            Gallery
          </a>
        </router-link>
      </PopoverGroup>
    </div>
  </Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'

const discover = [
  {
    name: 'Hieronymus Bosch',
    description: 'Get a deep look into the "The Garden of Earthly Delights".',
    href: '#',
  },
  {
    name: 'Paul Klee',
    description: 'Paul Klee was a Swiss-born German artist. His highly individual style was influenced by movements in art that included expressionism, cubism, and surrealism.',
    href: '#',
  },
  {
    name: 'Casper David Friedrich',
    description: 'A 19th-century German Romantic landscape painter, generally considered the most important German artist of his generation.',
    href: '#',
  },
  {
    name: 'Wassily Kandinsky',
    description: "A Russian painter and art theorist. Kandinsky is generally credited as the pioneer of abstract art.",
    href: '#',
  },
  {
    name: 'Matthieu Faubourg',
    description: 'A deep dive into inspiration originated from music and art',
    href: '#',
  },
]
const more = [
  {
    name: 'About Us',
    description: 'Reach out for us, if you got a story to share.',
    href: '#',
  },
  { 
    name: 'Gallery', 
    description: 'Overview all topics curated on in one place.', 
    href: '#' 
  },
]

export default {
  name: 'Header',

  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    ChevronDownIcon
  },
  setup() {
    return {
      discover,
      more,
    }
  },
}
</script>