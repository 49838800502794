<template>
  <Popover open="true" class="relative bg-white">
    <div class="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
      <div class="flex justify-start lg:w-0 lg:flex-1">
        <a href="/">
          <span class="sr-only">Curated</span>
          <img class="h-8 w-auto sm:h-12" src="../assets/svg/Curated.svg" alt="" />
        </a>
      </div>
      <PopoverGroup as="nav" class="hidden md:flex space-x-10">
        <Popover class="relative" v-slot="{ open }">
          <PopoverButton :class="[open ? 'text-black' : 'text-black', 'group bg-white rounded-md inline-flex items-center text-base text-2xl font-medium hover:text-gray-900 focus:outline-none']">
            <span>Discover</span>
            <ChevronDownIcon :class="[open ? 'text-black' : 'text-black', 'ml-2 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
          </PopoverButton>

          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel class="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
              <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                  <a v-for="item in discover" :key="item.name" :href="item.href" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                    <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-black text-white sm:h-12 sm:w-12">
                      <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                    </div>
                    <div class="ml-4">
                      <p class="text-base font-medium text-gray-900">
                        {{ item.name }}
                      </p>
                      <p class="mt-1 text-sm text-gray-500">
                        {{ item.description }}
                      </p>
                    </div>
                  </a>
                </div>
                <div class="p-5 bg-gray-50 sm:p-8">
                  <a href="#" class="-m-3 p-3 flow-root rounded-md hover:bg-gray-100">
                    <div class="flex items-center">
                      <div class="text-base font-medium text-gray-900">
                        Random
                      </div>
                      <span class="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 bg-black text-white">
                        New
                      </span>
                    </div>
                    <p class="mt-1 text-sm text-gray-500">
                      Sick of making decisions? We will find something for you.
                    </p>
                  </a>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <Popover class="relative" v-slot="{ open }">
          <PopoverButton :class="[open ? 'text-black' : 'text-black', 'group bg-white rounded-md inline-flex items-center text-base text-2xl font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black']">
            <span>Curators</span>
            <ChevronDownIcon :class="[open ? 'text-black' : 'text-black', 'ml-2 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
          </PopoverButton>

          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
            <PopoverPanel class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">
              <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                  <a v-for="item in curators" :key="item.name" :href="item.href" class="-m-3 p-3 block rounded-md hover:bg-gray-50">
                    <p class="text-base font-medium text-gray-900">
                      {{ item.name }}
                    </p>
                    <p class="mt-1 text-sm text-gray-500">
                      {{ item.description }}
                    </p>
                  </a>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <router-link to="/gallery">
          <a class="text-base text-2xl font-medium text-black hover:text-gray-500">
            Gallery
          </a>
        </router-link>
        <router-link to="/about">
          <a class="text-base text-2xl font-medium text-black hover:text-gray-500">
            About
          </a>
        </router-link>
        </PopoverGroup>
        <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <a href="#" class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-black hover:bg-gray-200 hover:text-black">
            Get Involved
          </a>
      </div>
    </div>
  </Popover>
</template>

<script>
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import {
  ChartBarIcon,
  CursorClickIcon,
  DocumentReportIcon,
  RefreshIcon,
  ViewGridIcon,
} from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import Curated from '../assets/svg/Curated.svg'

const discover = [
  {
    name: 'Hieronymus Bosch',
    description: 'Get a deep look into the "The Garden of Earthly Delights".',
    href: '#',
    icon: ChartBarIcon,
  },
  {
    name: 'Paul Klee',
    description: 'Paul Klee was a Swiss-born German artist. His highly individual style was influenced by movements in art that included expressionism, cubism, and surrealism.',
    href: '#',
    icon: RefreshIcon,
  },
  {
    name: 'Casper David Friedrich',
    description: 'A 19th-century German Romantic landscape painter, generally considered the most important German artist of his generation.',
    href: '#',
    icon: CursorClickIcon,
  },
  {
    name: 'Wassily Kandinsky',
    description: "A Russian painter and art theorist. Kandinsky is generally credited as the pioneer of abstract art.",
    href: '#',
    icon: ViewGridIcon,
  },
  {
    name: 'Matthieu Faubourg',
    description: 'A deep dive into inspiration originated from music and art',
    href: '#',
    icon: DocumentReportIcon,
  },
]
const curators = [
  {
    name: 'About Us',
    description: 'Why we do thing the way we do.',
    href: '#',
  },
  { name: 'Contribute', description: 'Reach out for us, if you got a story to share.', href: '#' }
]

export default {
  name: 'Header',

  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    ChevronDownIcon,
    Curated
  },
  setup() {
    return {
      discover,
      curators,
    }
  },
}
</script>