<template>
  <div id="app">
    <div class="header">
      <Header />
    </div>
    <div class="header-mobile">
      <HeaderMobile />
    </div>
    <router-view/>
    <div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import HeaderMobile from './components/HeaderMobile.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    HeaderMobile,
    Footer
  }
}
</script>

<style>
#app {
  font-family: 'Georgia';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.header .header-mobile {
  margin-bottom: 2rem;
}

@media only screen and (max-width: 1000px) {
  .header {
    display: none;
  }
}
@media only screen and (min-width: 999px) {
  .header-mobile {
    display: none;
  }
}
</style>
