<template>
    <div class="relative">
        <div class="absolute inset-x-0 bottom-0 h-1/2" />

        <div class="max-w-7xl mx-auto px-6 lg:px-8">
            <div class="relative shadow-xl rounded-2xl overflow-hidden">
                <div class="absolute inset-0">
                <img class="h-full w-full object-cover" src="../../assets/img/BoschTheCrucifixionOfStJulia.jpg" alt="A painting." />
                <div class="absolute inset-0 bg-gradient-to-r from-gray-800 to-gray-300" style="mix-blend-mode: multiply;" />
                </div>
                <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-3xl lg:text-6xl">
                    <span class="block text-gray-200">Spotlight</span>
                </h1>
                <p class="mt-6 max-w-lg mx-auto text-center text-lg text-gray-400 sm:max-w-3xl">
                    The Crucifixion of St Julia is a triptych by the Dutch painter Hieronymus Bosch. Like many Bosch paintings, the date of this work was long disputed, until dendochronologic analysis assigned it to around 1497.
                </p>
                <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                    <div class="space-y-4 sm:space-y-0 sm:mx-auto sm:gap-5">
                    <a href="#" class="flex items-center justify-center py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-200 bg-opacity-20 hover:bg-opacity-30 sm:px-8">
                        Read more
                    </a>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'spotlight'
}
</script>

<style>

</style>